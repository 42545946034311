<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
//import axios from "axios";

/**
 * Profile component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Community",
    meta: [
      {
        name: "community",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Snella",
      user2: null,
      lienBanniereIt: "",
      srcBanniereIt: null,
      photoBanniereIt: null,
      lienBanniereFr: "",
      srcBanniereFr: null,
      photoBanniereFr: null,
      items: [
        {
          text: "Snella",
        },
        {
          text: "Snella",
          active: true,
        },
      ],
    };
  },
  methods: {
    async loadBanniere(lang) {
      var db = firebase.firestore();
      var docRef = db.collection("snella").doc(lang);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            var data = doc.data();
            if (lang == "it") {
              this.srcBanniereIt = data.srcBanniere;
              this.lienBanniereIt = data.lienBanniere;
            }
            if (lang == "fr") {
              this.srcBanniereFr = data.srcBanniere;
              this.lienBanniereFr = data.lienBanniere;
            }
            console.log("Document data:", doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    updateBanniere(lang) {

      console.log("url"+this.lienBanniereFr)
      var db = firebase.firestore();

      var type =
        lang == "it"
          ? this.photoBanniereIt.name.split(".").pop()
          : this.photoBanniereFr.name.split(".").pop();

      var filename = "snella/banniere" + "-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(lang == 'it' ? this.photoBanniereIt : this.photoBanniereFr);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
            db.collection("snella").doc(lang).update({
              srcBanniere: url,
              lienBanniere: lang == "it" ? this.lienBanniereIt : this.lienBanniereFr,
            });
            lang == "it"
              ? (this.srcBanniereIt = url)
              : (this.srcBanniereFr = url);
          });
        }
      );
    },
  },

  create() {},
  mounted() {
    this.user2 = localStorage.getItem("user2");
    this.loadBanniere("fr");
    this.loadBanniere("it");
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <label class="form-label">🇮🇹 Bannière en Italien (800x250px):</label
        ><br />
        <img
          width="800px"
          height="250px"
          v-if="srcBanniereIt != null"
          :src="srcBanniereIt"
          :key="'banniereit'"
          alt
          class="img-thumbnail"
        />
        <b-form-file
          v-model="photoBanniereIt"
          placeholder="Select photo..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <br /><br />
        <label class="form-label">🇮🇹 Lien en italien:</label>
        <input
          type="text"
          class="form-control"
          :id="'lienbanniereit_' + key"
          v-model="lienBanniereIt"
        />
        <button
          class="btn btn-success btn-large mt-2"
          @click="updateBanniere('it')"
          type="button"
        >
          <span
            v-if="saving_modification_lienbanniere_it"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Save Modifications IT
        </button>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <label class="form-label">🇫🇷 Bannière en Francais (800x250px):</label
        ><br />
        <img
          width="800px"
          height="250px"
          v-if="srcBanniereFr != null"
          :src="srcBanniereFr"
          :key="'bannierefr'"
          alt
          class="img-thumbnail"
        />
        <b-form-file
          v-model="photoBanniereFr"
          placeholder="Select photo..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <br /><br />
        <label class="form-label">🇫🇷 Lien en francais:</label><br />
        <input
          type="text"
          class="form-control"
          :id="'lienbannierefr_' + key"
          v-model="lienBanniereFr"
        />
        <button
          class="btn btn-success btn-large mt-2"
          @click="updateBanniere('fr')"
          type="button"
        >
          <span
            v-if="saving_modification_lienbanniere_it"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Save Modifications FR
        </button>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
